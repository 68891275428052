import React, { useState } from "react";
import { graphql } from "gatsby";
import _ from "lodash";

import { Layout, AppImage, FilterSliderSection } from "../components";

const CareDelivery = ({ data }) => {
  const careDeliverySlides = _.sortBy(
    _.get(data, "allContentfulCareDeliverySlide.nodes"),
    "order"
  );
  const [selected, setSelected] = useState(0);

  const filters = careDeliverySlides.map((slide, index) => {
    let combineOffsets = 0;
    careDeliverySlides.forEach((previousSlide) => {
      if (previousSlide.order <= slide.order) {
        combineOffsets = combineOffsets + parseFloat(previousSlide.offset);
      }
    });
    return {
      order: slide.order,
      title: slide.order === 0 ? "All" : slide.title,
      offset: combineOffsets + index * 24,
    };
  });

  const renderDesktopImage = (param) => {
    switch (param) {
      case 1:
        return careDeliverySlides[1].image;
      case 2:
        return careDeliverySlides[2].image;
      case 3:
        return careDeliverySlides[3].image;
      case 4:
        return careDeliverySlides[4].image;
      default:
        return careDeliverySlides[0].image;
    }
  };

  const slides = careDeliverySlides.map((slide) => {
    return {
      order: slide.order,
      title: slide.title,
      description: _.get(slide, "description.internal.content"),
      image: slide.image,
      video: _.get(slide, "video") || null,
    };
  });

  const slidesContainer = (mobile) => {
    return slides.map((slide, index) => {
      return (
        <div
          key={index}
          className={`filter-slider-child description-column${
            mobile ? " mobile" : " desktop"
          }${selected === slide.order ? " selected" : ""}`}
        >
          {mobile &&
            (slide.video ? (
              <img
                className="main-image video columns"
                src={_.get(careDeliverySlides[0].video, "file.url")}
                alt="care delivery animation"
              />
            ) : (
              /*<video playsinline autoPlay muted loop>
                  <source src={_.get(slide, "video.file.url")} type="video/mp4" />
                </video>*/
              <AppImage
                className="description-image"
                image={slide.image}
                aspectRatio={1800 / 1080}
              />
            ))}
          {slide.order === 0 ? (
            <h2 className="h3 description-title">{slide.title}</h2>
          ) : (
            <h3 className="h4 description-title">{slide.title}</h3>
          )}
          <p className="description-text">{slide.description}</p>
        </div>
      );
    });
  };

  return (
    <Layout
      className="care-delivery"
      pageData={_.get(data, "contentfulPageData")}
    >
      <FilterSliderSection
        filters={filters}
        sliderChildren={slidesContainer(true)}
        selected={selected}
        setSelected={setSelected}
        // hideZeroOption
      >
        <div className="container desktop flex">
          {selected === 0 && careDeliverySlides[0].video ? (
            <div className="main-image columns">
              <img
                className="video"
                src={_.get(careDeliverySlides[0].video, "file.url")}
                alt="care delivery animation"
              />
              {/*<video controls autoPlay loop muted>
                <source src={_.get(careDeliverySlides[0].video, "file.url")} type="video/mp4" />
              </video>*/}
            </div>
          ) : (
            <AppImage
              className="main-image columns"
              image={renderDesktopImage(selected)}
              aspectRatio={1800 / 1080}
            />
          )}
          <div className="description columns">{slidesContainer(false)}</div>
        </div>
      </FilterSliderSection>
    </Layout>
  );
};

export default CareDelivery;

export const useTeamImage = graphql`
  query careDeliveryQuery {
    contentfulPageData(title: { eq: "Care Delivery" }) {
      title
      description
      image {
        file {
          url
        }
      }
      header
    }
    allContentfulCareDeliverySlide {
      nodes {
        order
        title
        description {
          internal {
            content
          }
        }
        image {
          title
          fluid(maxWidth: 1024, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        offset
        video {
          file {
            url
          }
        }
      }
    }
  }
`;
